<template>
  <v-card outlined>
    <v-card-text>
      <small v-if="!seedData.length" aut-no-values-message class="red--text">
        No options added yet. Please click the "Add" button to add one
      </small>
      <v-card
        v-for="(keyValue, i) in seedData"
        :key="i"
        aut-seeddata-keyvalue
        class="mt-2"
        outlined
      >
        <div class="float-right grey--text ma-2" aut-delete>
          <EditorAction :action="designAction" @remove="removeKeyValue(i)" />
        </div>
        <v-row dense>
          <v-col cols="12" lg="2">
            <v-text-field
              dense
              aut-keyvalue-id
              label="ID"
              ref="focus"
              autofocus
              :rules="idRules"
              v-model="keyValue.id"
              required
              :error-messages="getErrorMessageKey(i)"
              @change="addKey(i)"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="10">
            <v-text-field
              aut-keyvalue-label
              dense
              small
              required
              v-model="keyValue.name"
              :error-messages="getErrorMessageValue(i)"
              :label="label"
              :rules="labelRules"
              @change="addValue(i)"
            />
          </v-col>
        </v-row>
      </v-card>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn @click="addKeyValue" aut-action-add-keyvalue>Add</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import EditorAction from "@/components/editor/EditorActions/EditorAction.vue";
import { clone, rules } from "@/util.js";
import { isPlainObject } from "lodash";
const debug = require("debug")("atman.components.item_object"); // eslint-disable-line
const isDuplicate = (seedValues, index, fieldName) => {
  if (!seedValues.length) {
    return false;
  }
  let isADuplicate = false;
  if (typeof seedValues[0] == "string") {
    return seedValues.filter((item) => item == fieldName).length > 1;
  }
  const beingAdded = seedValues[index];
  seedValues.every((seedValue, i) => {
    if (index == i) {
      return true;
    }
    if (!fieldName && seedValue == beingAdded) {
      isADuplicate = true;
      return false;
    } else if (seedValue[fieldName] == beingAdded[fieldName]) {
      isADuplicate = true;
      return false;
    }
    return true;
  });
  return isADuplicate;
};

const emptyItem = () => {
  return {
    id: "",
    name: "",
  };
};
export default {
  name: "KeyValueList",
  props: {
    data: {
      type: Array,
    },
    label: {
      type: String,
      default: "Name",
    },
  },
  components: {
    EditorAction,
  },
  data() {
    return {
      seedData: [],
      idRules: [rules.mandatory()],
      labelRules: [rules.mandatory()],
      errorMessages: {},
    };
  },
  created() {
    this.designAction = {
      icon: "mdi-delete",
      id: "remove-keyvalue",
      confirmation: "Are you sure you want to continue?",
      label: "Remove",
      event: "remove",
    };
  },
  mounted() {
    this.determineSeedData();
  },
  methods: {
    determineSeedData() {
      const result = clone(this.data || []).map((item) => {
        if (isPlainObject(item)) {
          return item;
        }
        return {
          id: item,
          name: item,
        };
      });
      if (!result.length) {
        result.push(emptyItem());
      }
      this.seedData = result;
    },
    addKeyValue() {
      this.seedData.push(emptyItem());
    },
    removeKeyValue(i) {
      this.seedData.splice(i, 1);
      this.updateDefinition();
    },
    addKey(index) {
      const key = `${index}key`;
      this.$set(this.errorMessages, key, null);
      if (isDuplicate(this.seedData, index, "id")) {
        this.$set(this.errorMessages, key, "Duplicate ID");
        return;
      }
      this.updateDefinition();
    },
    addValue(index) {
      const key = `${index}value`;
      this.$set(this.errorMessages, key, null);
      if (isDuplicate(this.seedData, index, "name")) {
        this.$set(this.errorMessages, key, "Duplicate Value");
        return;
      }
      this.updateDefinition();
    },
    getErrorMessageKey(index) {
      return this.errorMessages[`${index}key`];
    },
    getErrorMessageValue(index) {
      return this.errorMessages[`${index}value`];
    },
    updateDefinition() {
      this.$emit("update", this.seedData);
    },
  },
};
</script>
